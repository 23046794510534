window.ENV = import.meta.env.MODE;
window.ENV_DEV = (window.ENV !== null && window.ENV !== "production");

import Toastr from 'toastr2';
window.toastr = new Toastr({
  timeOut: 25000
});


import * as Sentry from "@sentry/browser";

if(!window.ENV_DEV) {
    Sentry.init({
        dsn: "https://84ce19bc4a89519e56f74fa01fcf8dd8@o4504502656499712.ingest.sentry.io/4505737180807168",

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 1.0,

        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        integrations: [
            new Sentry.Replay({
                // Additional SDK configuration goes in here, for example:
                maskAllText: true,
                blockAllMedia: true,
            }),
        ],
    });
}
